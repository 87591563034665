<mat-toolbar id="navbar">

    <span></span>
    <!--boton para la vista inicio-->
    <button id="menuinicio" mat-button routerLink="/inicio">
        <img src="../../../../../assets/img/Diseño Logo/ec/imagen_2024-11-11_153602098.png">

    </button>

    
    <span class="example-spacer"></span>

<!--Boton Inicio sesion-->
    <button id="bn1" mat-button [matMenuTriggerFor]="usuario">
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-person"
            viewBox="0 0 16 16" >
            <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
        </svg>

    </button>

    <mat-menu #usuario="matMenu">
        <button mat-menu-item (click)="ingresar()" *ngIf="logueado" routerLink="/registro"> <b>Registrarse</b> </button>
        <button mat-menu-item (click)="ingresar()" *ngIf="logueado" routerLink="/iniciosesion"> <b>Iniciar sesión</b></button>
        <button mat-menu-item  (click)="cerrarsesion()" *ngIf="deslogueado">Cerrar sesión</button>
    </mat-menu>


    <!--carrito-->
    <button mat-icon-button id="carrito" aria-label="Example icon-button with heart icon" routerLink="/carrito">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16"  >
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
           </svg>
    </button>
    
  
        <button class="btn "  data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#88c425" class="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
            </svg>
            
        </button>
        <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
          <div class="offcanvas-header">
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <h1>Productos</h1>
        
            <button mat-menu-item routerLink="/suplementos"> - Suplementos </button> 
            <button mat-menu-item routerLink="calzado">  - Calzado </button> 
            <button mat-menu-item routerLink="ropa"> - Ropa</button>
            <button mat-menu-item routerLink="materiales"> - Materiales</button>
            
            <br>

            <button mat-menu-item routerLink="sobre-nosotros"><h1>Sobre Nosotros</h1></button>          
            <br>
            <button class="btn"><h1>Guia de talles</h1></button>
          </div>
        </div>



</mat-toolbar>