import { Component } from '@angular/core';
import { Articulos } from 'src/app/models/articulos';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {
  
  
}
