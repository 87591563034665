<section id="sobre-nosotros" class="sobre-nosotros">
    <div class="seccion" id="que-es">
      
      <h2>¿Qué es Sport Tech?</h2>
      <p>Sport Tech es una tienda deportiva con estilo Sportwear, especializada en ofrecer productos de calidad para atletas y amantes del deporte, Sport Tech ofrece productos de alta calidad diseñados tanto para atletas como para aficionados al deporte que valoran tanto el rendimiento como el estilo.</p>
      <p>Desde indumentaria y calzado hasta suplementos y equipamiento especializado, cada producto es cuidadosamente seleccionado para ofrecer una combinación perfecta de funcionalidad y diseño. Nuestra misión es brindar opciones que no solo potencien el rendimiento de nuestros clientes, sino que también les permitan expresar su autenticidad y actitud.</p>
    </div>
    
    <div class="seccion" id="por-que-elegirnos">
      <h2>¿Por qué elegirnos?</h2>
      <p>Nos destacamos por nuestra selección de productos exclusivos y nuestro compromiso con la innovación en moda y rendimiento.</p>
    </div>
    
    <div class="seccion" id="fundadores">
      <h2>Fundadores</h2>
      <div class="fundador">
        <img src="../../../assets/img/Nosotros/exe.jpeg" alt="Fundador 1">
        <p>Exequiel Lleubul - Programador Principal</p>
      </div>
      <div class="fundador">
        <img src="../../../assets/img/Nosotros/tino.jpeg" alt="Fundador 2">
        <p> Valentino Sario - Encargado de Documentación</p>
      </div>
    </div>
  </section>
  