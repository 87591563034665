<form class="form" (ngSubmit)="resetPassword()">
    <p id="heading">Ingrese su email</p>
    <div class="field">
      <i class="material-icons" style="font-size: 18px;">person</i>
     
      <input autocomplete="off" [(ngModel)]="resetEmail" [ngModelOptions]="{standalone: true}" placeholder="Ingresa tu correo electrónico" class="input-field" type="email" required>
    </div>
  
    <div class="btn"> 
      <button (click)="resetPassword()" class="button">Enviar correo </button>
    </div>
    <p #responseMessage></p>
  </form>
  
  
  
  
  

