export const environment = {
    production: false,

     firebaseConfig : {
        apiKey: "AIzaSyDm9cYvWgu21ofyNh7dw5D0Fcm6vTcEz8Y",
        authDomain: "bdpracticas-e77fe.firebaseapp.com",
        projectId: "bdpracticas-e77fe",
        storageBucket: "bdpracticas-e77fe.appspot.com",
        messagingSenderId: "344268534782",
        appId: "1:344268534782:web:9d2c648fa8debe702fc6af",
        measurementId: "G-J1DPNQV0BJ"
    }
}